<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md6></v-flex>
            <v-flex md6 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">NEWS</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">新闻资讯</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">新闻资讯</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="ctk-page-title wow fadeInUp" id="news-top">新闻资讯</div>
        <!--<v-tabs
          class="ctk-tabs"
          centered
          center-active
          hide-slider
          :show-arrows="false"
          v-model="type"
        >
          <v-tab :ripple="false"><a href="/news">全部</a></v-tab>
          <v-tab :ripple="false"><a href="/news/1">企业动态</a></v-tab>
          <v-tab :ripple="false"><a href="/news/2">媒体报道</a></v-tab>
        </v-tabs>-->
        <v-layout wrap v-if="news.data">
          <v-flex xs12 md4 v-for="(item, ci) in news.data" :key="item.key" :class="'news-flex news-flex' + ci % 3">
            <v-card flat tile class="news-card">
              <a :href="'/new/' + item.id">
                <div class="news-img wowd fadeInUp"><img :src="item.attach.path"/></div>
                <div class="news-title wowd fadeInUp" data-wow-delay="0.4s">{{item.title}}</div>
                <div class="news-action wowd fadeInUp" data-wow-delay="0.6s">
                  <span class="news-type">{{types[item.type]}}</span>
                  <span class="news-date">{{item.create_time.substring(0, 10)}}</span>
                </div>
              </a>
            </v-card>
          </v-flex>
        </v-layout>
      <template v-if="totalPage > 1">
        <div class="text-center">
          <v-pagination v-model="page" :length="totalPage"></v-pagination>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '新闻资讯', disabled: true }
    ],
    news: [],
    totalPage: 1,
    types: { 2: '企业动态', 3: '媒体报道' },
    type: 0,
    page: 1
  }),
  created () {
    document.title = '新闻资讯-好睡眠'
    document.querySelector('#menu_news').classList.add('v-btn--active')
    if (this.$route.params.type === undefined) {
      this.type = 0
    } else {
      this.type = parseInt(this.$route.params.type)
    }
    if (this.$route.params.page === undefined) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.params.page)
    }
    this.getNews(this.type, this.page)
  },
  mounted () {
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        if (val.page !== 1) {
          this.$router.push({ name: 'news', params: { type: val.type, page: val.page } })
        } else {
          this.$router.push({ name: 'news', params: { type: val.type } })
        }
      },
      deep: true
    },
    '$route' (to, from) {
      document.querySelector('.ctk-top-bar').scrollIntoView(true)
      this.getNews(this.type, this.page)
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    },
    params () {
      const { type, page } = this
      return { type, page }
    }
  },
  methods: {
    getNews (type, page) {
      this.totalPage = 1
      if (type !== 0) {
        type += 1
      }
      this.https.get('news', { type: type, page: page, pageSize: 6 }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
        }
      }).then(_ => {
        this.$wowd.init()
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/news.css';
</style>
